import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import '../../styles/css/TopBar.css'
import logoImage from '../../styles/images/sqwad-hand.png';

class TopBar extends Component {
    toggleMenu(){
        const sideBar = document.getElementsByClassName('admin-sidebar')[0]
        if(window.innerWidth <= 767){
            if(sideBar.style.display === "none" || sideBar.style.display === ""){
                sideBar.style.display = "block"
            } else {
                sideBar.style.display = "none"
            }
        }
    }
    render() {
        return (
          <div className="admin-topbar" onClick={this.toggleMenu}>
            <button className="button-menu-mobile open-left disable-btn">
              <i className="fa fa-bars"/>
            </button>
            <p className="topBarText">
                <img src={logoImage} width="50px" height="auto" alt="Team" className="rounded-circle" style={{marginRight:'5px'}}/>
                SQWAD
            </p>
          </div>
        );
    }
}

export default TopBar
