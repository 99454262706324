import app from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore';
import Rebase from 're-base';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

// const app = firebase.initializeApp(config);
// const appDatabasePrimaryFunctions = firebase.database(app);
// const base = Rebase.createClass(appDatabasePrimaryFunctions);

const db = app.firestore(app.initializeApp(config));
db.settings({ timestampsInSnapshots: true });
const base = Rebase.createClass(db);

export { app, base, db}


