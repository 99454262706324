import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import SetUpTickets from './components/admin/SetUpTickets';
import {SetUpGame} from './components/admin/SetUpGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import SetUpFollowUpEmail from './components/admin/SetUpFollowUpEmail';
import LoginVariables from './components/admin/SetUpLoginVariables';
import SetUpApi from './components/admin/SetUpApi';
import SetUpWebHooks from './components/admin/SetUpWebHooks';
import ApiDocumentation from './components/admin/ApiDocumentation';
import { app } from './base'
import ViewUser from "./components/admin/ViewUser";

function AuthenticatedAdminRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
              if(authenticated === true){
                return (
                  <Component {...props} {...rest} />
                )
              } else {
                return (
                  <Redirect to={{pathname: '/adminLogin', state: {from: props.location}}} />
                )
              }
              }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true
        }
    }

    setCurrentUser(user) {
      if (user) {
          this.setState({
              currentUser: user,
              authenticated: true
          })
      } else {
          this.setState({
              currentUser: null,
              authenticated: false
          })
      }
    }

    componentDidMount() {
        this.fireBaseListener = app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false
                })
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false
                })
            }
        })
    }

    componentWillUnmount() {
        this.fireBaseListener && this.fireBaseListener();
        this.authListener = undefined;
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                            exact
                            path="/adminlogin"
                            user={this.state.currentUser}
                            render={(props) => {
                              return <AdminLogin setCurrentUser={this.setCurrentUser} {...props} />
                            }} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/admin"
                            authenticated={this.state.authenticated}
                            component={AdminHome} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/setupgame"
                            authenticated={this.state.authenticated}
                            currentUser={this.state.currentUser}
                            component={SetUpGame} />

                        {/*<AuthenticatedAdminRoute*/}
                        {/*    exact*/}
                        {/*    path="/setupreveals"*/}
                        {/*    authenticated={this.state.authenticated}*/}
                        {/*    component={SetUpReveal} />*/}

                        <AuthenticatedAdminRoute
                            exact
                            path="/setuptickets"
                            authenticated={this.state.authenticated}
                            component={SetUpTickets} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/setupteamvariables"
                            authenticated={this.state.authenticated}
                            component={SetUpTeamVariables} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/setuprulesandregs"
                            authenticated={this.state.authenticated}
                            component={SetUpRulesAndRegs} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/setupticketemail"
                            authenticated={this.state.authenticated}
                            component={SetUpTicketEmail} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/setupfollowupemail"
                            authenticated={this.state.authenticated}
                            component={SetUpFollowUpEmail} />

                        <AuthenticatedAdminRoute
                              exact
                              path="/setuploginvariables"
                              authenticated={this.state.authenticated}
                              component={LoginVariables} />

                        <AuthenticatedAdminRoute
                              exact
                              path="/apimanagement"
                              authenticated={this.state.authenticated}
                              component={SetUpApi} />

                        <AuthenticatedAdminRoute
                            exact
                            path="/webhooks"
                            authenticated={this.state.authenticated}
                            component={SetUpWebHooks} />

                        <AuthenticatedAdminRoute
                              exact
                              path="/apidocumentation"
                              authenticated={this.state.authenticated}
                              component={ApiDocumentation} />

                          <AuthenticatedAdminRoute
                              exact
                              path="/user"
                              authenticated={this.state.authenticated}
                              component={ViewUser} />

                        <Redirect to="/adminlogin"/>
                      </Switch>
                    </div>
                </div>
            </BrowserRouter>
          </div>
        )
    }
}

export default App;
