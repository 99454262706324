import React, { useState, useEffect } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { db } from '../../base';
import '../../styles/css/AdminMain.css';
import { getURLParameter, convertTimeStampToHumanReadable } from 'myshared';

const ViewUser = (props) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const uid = getURLParameter('user');
            if (!uid) {
                return;
            }
            try {
                const userRef = await db.collection("users").doc(uid).get();
                setUser(userRef.data());
                setLoading(false);
            } catch (error) {
                console.error("Error fetching user: ", error);
                // Optionally handle the error in some way, e.g.:
                // setLoading(false);
            }
        };

        fetchUser();
    }, []);  // The empty array means this useEffect runs once, similar to componentDidMount.

    return (
        <div className="admin-wrapper d-flex">
            <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
            <SideMenu />
            <div className="flex-grow-1">
                <TopMenu />
                <div className="admin-main-panel p-4">
                    <div className="container-out">
                        <div className="admin-form-box bg-white p-4 rounded shadow-sm">
                            {user &&
                                <div>
                                    <h3 className="mb-3">User</h3>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"><strong>ID:</strong> {user.uid}</li>
                                        {user.email && <li className="list-group-item"><strong>Email:</strong> {user.email}</li>}
                                        {user.phoneNumber && <li className="list-group-item"><strong>Phone Number:</strong> {user.phoneNumber}</li>}
                                        {user.name && <li className="list-group-item"><strong>Name:</strong> {user.name}</li>}
                                        <li className="list-group-item"><strong>Signed Up:</strong> {convertTimeStampToHumanReadable(user.signUpTime)}</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewUser;
