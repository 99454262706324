import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import '../../styles/css/ApiDocumentation.css';

class ApiDocumentation extends Component {
    constructor(props) {
        super(props);
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api`
        this.state = {
            loading: true,
            mainApiUrl: url
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel" style={{color: "black"}}>
                <p className="admin-header-text" style={{marginBottom:0}}>Documentation</p>
                <p className="admin-subheader-text">How to use the games API</p>
                <div className="section-tandem">
                    <div className="header-api">
                        Base URL:
                    </div>
                    <div className="body-api">
                        {this.state.mainApiUrl}
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Endpoints:
                    </div>
                    <div className="body-api">
                        /currentGameUserData
                    </div>
                    <div className="body-api">
                        /historicalUserData
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Request Format:
                    </div>
                    <div className="body-api">
                        - method: 'POST'
                        <br/>
                        HEADERS:
                        <br/>
                        - Content-Type: application/json
                        <br/>
                        BODY:
                        <br/>
                        <ul>
                            <li>apiKey (string, required)</li>
                            <li>startTime (int, required, milliseconds from unix epoch)</li>
                            <li>endTime (int, optional, milliseconds from unix epoch)</li>
                            {/*<li>winner (bol, optional)</li>*/}
                            <li>page (int, required)</li>
                            {/*<li>eventNameToSearchFor (string, optional, must be exact & case sensitive)</li>*/}
                        </ul>
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Response Format (JSON):
                    </div>
                    <div className="body-api">
                        - result: [
                        <br/>
                        Array of users that fit request criteria -- Users Format:
                        <div style={{marginLeft: 10}}>
                            {/*event: "Test Game 8"*/}
                            {/*<br/>*/}
                            {/*event_id: "example_unique_event_id_generated_by_sqwad" (important in circumstances where the admin names the event the same name)*/}
                            {/*<br/>*/}
                            email: "example@email.com"
                            {/*<br/>*/}
                            {/*activation: "race"*/}
                            <br/>
                            user_id: "example_user_id"
                            <br/>
                            interaction_datetime: 1658766000000 (format is milliseconds from the Unix Epoch)
                            {/*<br/>*/}
                            {/*user_choice: "User Choice Name"*/}
                        </div>
                        ]
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Response Limits:
                    </div>
                    <div className="body-api">
                        Current responses are limited to 100 users in a response, to get the next 100 users in a request, ask for the next page
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Error Handling:
                    </div>
                    <div className="body-api">
                        If there is an error the result object will have a string instead of array returned; possible error responses
                        <br/>
                        <div>
                            result: "no_user_info" -- Means ill formatted request or info passed in request object is not correct
                            <br/>
                            result: "unauthorized" -- Means the api key is not generated or incorrect
                            <br/>
                            result: "too_many_requests_day" -- Means the number of requests are greater than 1440 on that day
                            <br/>
                            result: "too_fast_requests" -- Means making requests faster than 1 per second
                        </div>
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Request Throttling:
                    </div>
                    <div className="body-api">
                        We limit the number of requests to 1 per second and a total of 1440 requests per day
                    </div>
                </div>
            </div>
         </div>
        );
    }
}

export default ApiDocumentation;
